<template>
  <ColorsForm
    @validate="validate"
    :color="color"
    :valid="isFormValid"
    :type="'create'"
    ref="form"
    @save="createColor"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    ColorsForm: () => import('./ColorsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    askToLeave: true,
    color: {
      status: true,
      name: null,
      color_code: null,
      image_color: null,
    },
  }),
  methods: {
    validate(value) {
      this.isFormValid = value
    },
    createColor() {
      this.askToLeave = false
      const formData = new FormData()
      const colorKeys = Object.keys(this.color)
      const colorValues = Object.values(this.color)

      for (let i = 0; i < colorKeys.length; i++) {
        if (colorValues[i] !== null) {
          formData.append(colorKeys[i], colorValues[i])
        }
      }

      // this.$refs.form.validate();

      if (this.isFormValid) {
        this.$api
          .post('product_colors', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Cor cadastrada com sucesso',
              confirm: () => {
                this.$router.push({ name: 'colors' })
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar esta cor',
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar esta cor',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
